@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif !important;
  background-color: #f9f8ff !important;
}
a {
  color: inherit;
  text-decoration: inherit !important;
}
.invalid-feedback {
  display: block !important;
}
.error {
  border: solid thin #dd3444;
}
.w-100 {
  width: 100%;
}
.btnOutlinePurple {
  background: none !important;
  border-color: #7367f0 !important;
  color: #7367f0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.btnOutlineRed {
  background: none !important;
  border-color: #f06780 !important;
  color: #f06780 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.mr-2 {
  margin-right: 10px;
}
.modal-title {
  font-size: 1.3rem;
}
.printAble {
  display: none;
}
.card {
  border-radius: 10px !important;
  border: transparent !important;
}
@media print {
  body * {
    visibility: hidden;
  }
  .printAble,
  .printAble * {
    visibility: visible;
  }
  .printAble {
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
    display: block;
  }
  .printAble td {
    line-height: 1.5;
  }
}

